import styled from "styled-components";

export const MainSection = styled.div`
  border-radius: 9.011px;
  border: 1px solid #f0a500;
  background: rgba(178, 171, 171, 0.1);
  backdrop-filter: blur(16.399999618530273px);
  /* width: 705px; */
  /* padding: 0px 47px; */

  .close-icon {
    display: flex;
    justify-content: end;
    align-items: end;
    padding-right: 0.5rem;
    padding-top: 0.5rem;
  }
`;

export const Title = styled.h5`
  color: #fff;
  leading-trim: both;
  text-edge: cap;
  font-size: 22.809px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: capitalize;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 0rem;
`;

export const Text = styled.p`
  color: #fff;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 38.424px; /* 226.023% */
  padding: 0px 47px;
  margin-top: 0.7rem;
`;

export const BtnWrapper = styled.div`
  display: flex;
  gap: 2rem;
  justify-content: center;
  align-items: center;
  margin-bottom: 2.5rem;
`;

export const CancelBtn = styled.button`
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 6.12px;
  border: 0.72px solid #f0a500;
  width: 139.767px;
  height: 39.625px;
  background-color: transparent;

  &:hover {
    border-radius: 5px;
    border: 0.753px solid #f0a500;
    background: #f0a500;
    color: #fff;
  }
`;

export const ConformBtn = styled.button`
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 6.12px;
  border: 0.922px solid #f0a500;
  background: #f0a500;
  width: 139.767px;
  height: 39.625px;

  &:hover {
    border-radius: 5px;
    background: #885d00;
  }
`;
