import styled from "styled-components";

export const MainModel = styled.div``;

export const ModelsData = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  width: 400px;
  height: 300px;

  background: ${(p) =>
    p.theme.model.connectModelbg && p.theme.model.connectModelbg};

  border: "2px solid #000";
  text-align: center;
  border-radius: 30px 30px 30px 30px;
  @media (max-width: 420px) {
    width: 370px;
    height: 300px;
  }

  @media (max-width: 380px) {
    width: 330px;
    height: 300px;
  }
`;

export const ConnectButton = styled.button`
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
  border-radius: 30px;
  width: 360px;
  height: 110px;

  background: ${(p) =>
    p.theme.model.connectModelbtnBgColor &&
    p.theme.model.connectModelbtnBgColor};
  color: ${(p) =>
    p.theme.model.connectModelbtnTextColor &&
    p.theme.model.connectModelbtnTextColor};
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: 0.3s ease-in-out;
  cursor: pointer;

  border: none;
  font-weight: 700;
  font-size: 19px;
  @media (max-width: 415px) {
    width: 300px;
  }
`;

export const ConnectIcon = styled.img`
  width: 59.999px;
  height: 56.234px;
  object-fit: contain;
  opacity: 0.3;
`;

export const MainWrapper = styled.div`
  border-radius: 9.011px;
  border: 1px solid #f0a500;
  background: rgba(178, 171, 171, 0.1);
  backdrop-filter: blur(16.399999618530273px);
  width: 555px;
  padding-bottom: 2rem;

  @media (max-width: 767px) {
    width: 100%;
  }
  .close-icon {
    display: flex;
    justify-content: end;
    align-items: end;
    padding-right: 0.5rem;
    padding-top: 0.5rem;
  }

  .main-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const Title = styled.h5`
  color: #ffa701;
  font-size: 25.646px;
  font-style: normal;
  font-weight: 600;
  line-height: 38.424px; /* 149.823% */
  text-transform: capitalize;
  text-align: center;
`;

export const MainDiv = styled.div`
  border-radius: 5px;
  border: 1px solid #525253;
  width: 477px;
  padding: 10px 26px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
  cursor: pointer;

  @media (max-width: 767px) {
    width: 327px;
    padding: 5px 26px;
  }

  &:hover {
    border-radius: 5px;
    border: 1px solid #ffa701;
    background: #2b2b2b;

    h5 {
      color: #ffa701;
    }

    img {
      opacity: 1;
    }
  }
  h5 {
    color: #525253;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.627px; /* 90.113% */
    margin-bottom: 0rem;

    @media (max-width: 767px) {
      font-size: 20px;
    }
  }
`;
