import React, { useEffect, useState } from "react";
import {
  BodyDiv,
  ClaimBtn,
  ClaimRewardDiv,
  HeaderDiv,
  MainBodyDiv,
  MainTitle,
  MainWrapper,
  NoDataDiv,
  Tablediv,
} from "./elements";
import { Modal } from "antd";
import UnstakingModal from "components/common/modal/unstakingModal";
import Timer from "./timer";
import { Image } from "react-bootstrap";
import { nodata } from "assets";
import { CommonUtility } from "utility/common";
import environment from "environment";
import { useWalletConnectClient } from "services/walletServices";
import { stakingAbi } from "utility/abis/stakingAbi";
import Loading from "components/common/loaders/loading";
import { ToastMessage } from "components/common";
import { erc20Abi } from "utility/abis/erc20";

const TabsContent = ({ userStakes, getStakeInfo }) => {
  // console.log("USER STAKES", userStakes);
  const { web3Provider, chain, account } = useWalletConnectClient();
  const [isLoading, setIsLoading] = useState(false);
  const [currentTime, setCurrentTime] = useState(Number);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Math.floor(Date.now() / 1000));
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  const showModal = () => {
    setOpen(true);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  const data = [
    {
      stakedate: userStakes ? userStakes?.timestamp : "--",
      amountofstake: userStakes
        ? CommonUtility.decFixed(
            CommonUtility.convertFromWei(
              userStakes?.amount,
              environment.DEPOSIT_TOKEN_DECIMALS
            ),
            3
          )
        : "--",
      apy: userStakes ? `${userStakes?.apy} %` : "--",
      amountofrewards: userStakes
        ? CommonUtility.decFixed(
            CommonUtility.convertFromWei(
              userStakes?.rewards,
              environment.DEPOSIT_TOKEN_DECIMALS
            ),
            3
          )
        : "--",
      remainingtime: userStakes ? userStakes?.endTime : "--",
    },
  ];

  const claimRewards = async () => {
    try {
      setIsLoading(true);
      const contract = CommonUtility.contract(
        web3Provider,
        erc20Abi,
        environment.DEPOSIT_TOKEN[environment.DEFAULT_CHAIN]
      );
      const contractBalanceInWei = await contract.methods
        .balanceOf(environment.STAKING_CONTRACT[environment.DEFAULT_CHAIN])
        .call();
      const contractBalance = CommonUtility.convertFromWei(
        contractBalanceInWei.toString(),
        environment.DEPOSIT_TOKEN_DECIMALS
      );
      console.log("contractBalance", contractBalance);
      if (+contractBalanceInWei < +userStakes?.rewards)
        throw "Your reward is greater than contract balance.Please claim rewards later.Thanks";
      const stakingContract = CommonUtility.contract(
        web3Provider,
        stakingAbi,
        environment.STAKING_CONTRACT[environment.DEFAULT_CHAIN]
      );
      const estimatedGasLimit = await stakingContract.methods
        .claimRewards(userStakes?.tier)
        .estimateGas({ from: account });
      console.log("estimatedGasLimit", estimatedGasLimit);
      const currentGasPrice = await web3Provider.eth.getGasPrice();
      console.log("currentGasPrice", currentGasPrice);
      const txn = await stakingContract.methods
        .claimRewards(userStakes?.tier)
        .send({
          from: account,
          gasLimit: web3Provider.utils.toHex(+estimatedGasLimit + 100000),
          gasPrice: web3Provider.utils.toHex(+currentGasPrice + 1000),
        });
      if (txn && txn.code == 4001) throw "User denied the transaction";
      if (!txn.status) throw "Transaction Failed";
      if (txn.status) {
        setIsLoading(false);
        ToastMessage("Success!", "Transaction successful", "success");
      }
      setIsLoading(false);
      await getStakeInfo();
    } catch (error) {
      console.log("clAIM REWARDS ERROR", error);
      setIsLoading(false);
      if (error?.message) {
        ToastMessage("Error", error?.message, "error");
      } else {
        ToastMessage("Error", error, "error");
      }
    }
  };
  const handleUnstake = async () => {
    try {
      setIsLoading(true);
      console.log("userStakes?.tier", userStakes?.tier);
      const stakingContract = CommonUtility.contract(
        web3Provider,
        stakingAbi,
        environment.STAKING_CONTRACT[environment.DEFAULT_CHAIN]
      );
      const estimatedGasLimit = await stakingContract.methods
        .unstake(userStakes?.tier)
        .estimateGas({ from: account });
      console.log("estimatedGasLimit", estimatedGasLimit);
      const currentGasPrice = await web3Provider.eth.getGasPrice();
      console.log("currentGasPrice", currentGasPrice);
      const txn = await stakingContract.methods.unstake(userStakes?.tier).send({
        from: account,
        gasLimit: web3Provider.utils.toHex(+estimatedGasLimit + 100000),
        gasPrice: web3Provider.utils.toHex(+currentGasPrice + 1000),
      });
      if (txn && txn.code == 4001) throw "User denied the transaction";
      if (!txn.status) throw "Transaction Failed";
      if (txn.status) {
        setIsLoading(false);
        ToastMessage("Success!", "Transaction successful", "success");
      }
      setIsLoading(false);
      await getStakeInfo();
    } catch (error) {
      console.log("clAIM REWARDS ERROR", error);
      setIsLoading(false);
      if (error?.message) {
        ToastMessage("Error", error?.message, "error");
      } else {
        ToastMessage("Error", error, "error");
      }
    }
  };

  return (
    <MainWrapper>
      {isLoading && <Loading content={"Transaction in progress"} />}

      <Modal open={open} onCancel={handleCancel} centered width={705}>
        <UnstakingModal
          handleCancel={handleCancel}
          handleUnstake={handleUnstake}
        />
      </Modal>
      <div className="whole-wrapper">
        <div className="table-wrapper">
          <Tablediv>
            <HeaderDiv>
              <div>
                <h5>stake date</h5>
              </div>
              <div>
                <h5>amount of stake</h5>
              </div>
              <div>
                <h5>aPY</h5>
              </div>

              <div>
                <h5>amount of reward</h5>
              </div>

              <div>
                <h5>remaining time</h5>
              </div>

              <div>
                <h5>Action</h5>
              </div>
            </HeaderDiv>
            <MainBodyDiv>
              {data.length > 0 ? (
                <>
                  {data.map((item) => (
                    <>
                      <BodyDiv>
                        <div>
                          <h5>
                            {+item.stakedate > 0
                              ? CommonUtility.convertUnixTimestamp(
                                  item.stakedate
                                )
                              : "0/0/0000 "}
                          </h5>
                        </div>
                        <div>
                          <h5>{item.amountofstake}</h5>
                        </div>
                        <div>
                          <h5>{item.apy}</h5>
                        </div>
                        <div>
                          <h5>{item.amountofrewards}</h5>
                        </div>
                        <div>
                          {userStakes?.endTime > 0 && currentTime > 0 ? (
                            <h5>
                              <Timer
                                duration={+userStakes?.endTime - +currentTime}
                              />
                            </h5>
                          ) : (
                            <h5>00:00:00</h5>
                          )}
                        </div>
                        <div>
                          <button
                            className="unstake-btn"
                            onClick={showModal}
                            disabled={
                              !web3Provider ||
                              !userStakes ||
                              +userStakes?.amount == 0
                            }
                          >
                            Unstake
                          </button>
                        </div>
                      </BodyDiv>
                    </>
                  ))}
                </>
              ) : (
                <NoDataDiv>
                  <Image src={nodata} />
                  <h5>No data found!</h5>
                </NoDataDiv>
              )}
            </MainBodyDiv>
          </Tablediv>
        </div>

        <ClaimRewardDiv>
          <MainTitle>Claim Rewards</MainTitle>

          <div className="data-div">
            <h5>Amount staked</h5>
            <p>
              {userStakes
                ? CommonUtility.decFixed(
                    CommonUtility.convertFromWei(
                      userStakes?.amount,
                      environment.DEPOSIT_TOKEN_DECIMALS
                    ),
                    3
                  )
                : "--"}
            </p>
          </div>
          <div className="data-div">
            <h5>Total claimed</h5>
            <p>
              {userStakes
                ? CommonUtility.decFixed(
                    CommonUtility.convertFromWei(
                      userStakes?.claimed,
                      environment.DEPOSIT_TOKEN_DECIMALS
                    ),
                    3
                  )
                : "--"}
            </p>
          </div>
          <div className="data-div">
            <h5>Claimable</h5>
            <p>
              {userStakes
                ? CommonUtility.decFixed(
                    CommonUtility.convertFromWei(
                      userStakes?.rewards,
                      environment.DEPOSIT_TOKEN_DECIMALS
                    ),
                    3
                  )
                : "--"}
            </p>
          </div>

          <ClaimBtn
            disabled={!web3Provider || !userStakes || userStakes?.rewards == 0}
            onClick={() => claimRewards()}
          >
            Claim
          </ClaimBtn>
        </ClaimRewardDiv>
      </div>
    </MainWrapper>
  );
};

export default TabsContent;
