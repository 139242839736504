const environment = {
  BACKEND_BASE_URL: "http://localhost:8080",
  DEFAULT_CHAIN: 56,
  DEFAULT_HEX_CHAIN: "0x38",
  STAKING_CONTRACT: {
    11155111: "0x290295AdF8f6f102818Ba1AaAFA96Dc9d9B9E8D7",
    "eip155:11155111": "0x290295AdF8f6f102818Ba1AaAFA96Dc9d9B9E8D7",
    56: "0xCB564B0684F422E571E155f35F691041e603F56d",
    "eip155:56": "0xCB564B0684F422E571E155f35F691041e603F56d",
  },
  DEPOSIT_TOKEN: {
    11155111: "0x6E7dA2114C7b4054A4D81493014537181f2FBDAb",
    "eip155:11155111": "0x6E7dA2114C7b4054A4D81493014537181f2FBDAb",
    56: "0x57FC32015c09eF510584648b89BfE42642CD2d76",
    "eip155:56": "0x57FC32015c09eF510584648b89BfE42642CD2d76",
  },
  DEPOSIT_TOKEN_DECIMALS: 18,
  TIER_1: 0,
  TIER_2: 1,
  TIER_3: 2,
  TIER_4: 3,
  TIER_5: 4,
  TOTAL_TIERS: 5,
  TIER_APY: [16, 27, 39, 57, 81],
};
export default environment;
