import {
  MainModel,
  ConnectIcon,
  MainWrapper,
  Title,
  MainDiv,
} from "./connectModalElement";
import { Images } from "../../../../assets";
import { useWalletConnectClient } from "services/walletServices";
import { IoMdClose } from "react-icons/io";
import environment from "environment";
const ConnectModal = ({ closeModel }) => {
  const { connect, connectWithInjected } = useWalletConnectClient();
  const handleWeb3MetaMask = async () => {
    connectWithInjected(environment.DEFAULT_CHAIN);
    closeModel();
  };
  const handleWalletConnect = async () => {
    connect(`eip155:${environment.DEFAULT_CHAIN}`);
    closeModel();
  };

  return (
    <MainModel>
      <MainWrapper>
        <div className="close-icon">
          <IoMdClose
            color="rgba(255, 167, 1, 0.40)"
            fontSize={20}
            cursor="pointer"
            onClick={closeModel}
          />
        </div>
        <Title>Connect Wallet</Title>
        <div className="main-div">
          <MainDiv onClick={() => handleWeb3MetaMask()}>
            <ConnectIcon src={Images.web3.metamask} alt="icon" />
            <h5>Metamask</h5>
          </MainDiv>
          <MainDiv onClick={() => handleWalletConnect()}>
            <ConnectIcon
              src={Images.web3.wallet}
              className="trustwallet-btn-img"
            />
            <h5> Wallet Connect</h5>
          </MainDiv>
        </div>
      </MainWrapper>
    </MainModel>
  );
};

export default ConnectModal;
