import React from "react";
import { Text } from "./elements";

const FooterComp = () => {
  return (
    <div>
      <Text>
        Copyright ©{new Date().getFullYear()} Onschain All rights reserved
      </Text>
    </div>
  );
};

export default FooterComp;
