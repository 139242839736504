import styled from "styled-components";

export const MainSection = styled.section`
  margin-top: 6rem;

  .main-tab-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const TabsWrapper = styled.div`
  width: 464px;
  height: 52px;
  display: flex;
  gap: 10px;
  background-color: rgba(178, 171, 171, 0.1);
  backdrop-filter: blur(16.399999618530273px);
  border: 1px solid #525253;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  padding: 0rem 0.5rem;

  @media (max-width: 767px) {
    width: 100%;
  }

  div {
    flex: 1;
    display: flex;
    height: 37px;
    justify-content: center;
    align-items: center;
  }
  h5 {
    color: #525253;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 0rem;
    cursor: pointer;

    @media (max-width: 767px) {
      font-size: 10px;
    }
  }

  .tab-active {
    border-radius: 11px;
    background: #f0a500;
    cursor: pointer;

    h5 {
      color: #fff !important;
    }
  }

  .non-active {
    &:hover {
      border-radius: 11px;
      background: #323232;
      cursor: pointer;
    }
  }
`;

export const ContentDiv = styled.div`
  margin-top: 2.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const MainDiv = styled.div`
  border-radius: 9.011px;
  border: 1px solid #f0a500;
  background: rgba(178, 171, 171, 0.1);
  backdrop-filter: blur(16.399999618530273px);
  width: 670px;
  min-height: 293px;
  padding: 0rem 1.5rem;

  @media (max-width: 767px) {
    width: 100%;
  }

  .value-main-div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 1rem;
  }
`;

export const FirstDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 3rem;
  h5 {
    color: #f3f3f3;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 21.627px; /* 135.169% */
    margin-bottom: 0rem;

    @media (max-width: 767px) {
      font-size: 14px;
    }
  }

  p {
    color: #f0a500;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 21.627px; /* 135.169% */
    margin-bottom: 0rem;
    span {
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 21.627px; /* 135.169% */
      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
`;

export const FormDiv = styled.div`
  margin-top: 1rem;
  display: flex;
  .form-control {
    background-color: transparent;
    border-radius: 5px 0px 0px 5px;
    border-top: 0.5px solid #525253;
    border-bottom: 0.5px solid #525253;
    border-left: 0.5px solid #525253;
    border-right: none;
    color: #fff;

    @media (max-width: 767px) {
      font-size: 14px;
    }
    &::placeholder {
      color: rgba(255, 255, 255, 0.2);
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;

      @media (max-width: 767px) {
        font-size: 14px;
      }
    }

    &:focus {
      box-shadow: none !important;
      outline: none !important;
    }
  }
`;

export const MaxBtn = styled.button`
  color: #f0a500;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  border-radius: 5px;
  border: 0.5px solid #525253;
  width: 80px;
  height: 45px;
  background-color: rgba(74, 74, 74, 0.6);
  border: none;

  @media (max-width: 767px) {
    font-size: 14px;
  }

  &:hover {
    background: #f0a500;
    color: #fff;
  }

  &:disabled {
    background-color: rgba(74, 74, 74, 0.6);
    cursor: not-allowed;
    color: #fff;
    opacity: 0.4;
  }
`;

export const ValueDiv = styled.div`
  color: #f0a500;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  width: 56px;
  height: 35px;
  border: 1px solid rgba(74, 74, 74, 0.6);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: #f0a500;
    color: #fff;
  }

  &.disable {
    background-color: rgba(74, 74, 74, 0.6);
    color: #fff;
    cursor: not-allowed;
  }
`;

export const StakeBtn = styled.button`
  color: #fff;
  font-size: 23.039px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  border-radius: 5px;
  border: 1px solid #f0a500;
  width: 194px;
  height: 55px;
  background-color: transparent;
  margin-top: 1.5rem;
  display: block;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 767px) {
    font-size: 16px;
    width: 154px;
    height: 45px;
    margin-top: 2rem;
  }

  &:hover {
    border-radius: 5px;
    border: 1.28px solid #f0a500;
    background: #f0a500;
    color: #fff;
  }

  &:disabled {
    border-radius: 0.3125rem;
    border: 1px solid #525253;
    color: #fff;
    cursor: not-allowed;
    opacity: 0.4;
  }

  &:disabled:hover {
    background-color: transparent;
  }
`;
