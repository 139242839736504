import UnStakeComp from "components/unstakeComp";
import React from "react";

const Unstake = () => {
  return (
    <div>
      <UnStakeComp />
    </div>
  );
};

export default Unstake;
