export const Images = {
  web3: {
    metamask: require("./web3/metamask.png"),
    wallet: require("./web3/wallet.png"),
  },
  login: {
    bg: require("./images/bg-img.png"),
  },
};

export { default as homebg } from "./images/homebg.png";
export { default as logo } from "./images/logo.svg";
export { default as nodata } from "./images/nodata.svg";
