import styled from "styled-components";

export const MainSection = styled.section`
  .main-div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .main-row {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

export const Title = styled.h5`
  color: #fff;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 42.64px; /* 118.444% */
  text-transform: capitalize;
  text-align: center;
  margin-top: 1rem;
  padding-bottom: 1.5rem;
  span {
    color: #ffa701;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 42.64px;
    text-transform: capitalize;

    @media (max-width: 767px) {
      font-size: 24px;
    }
  }

  @media (max-width: 767px) {
    font-size: 24px;
  }
`;

export const CardDiv = styled.div`
  border-radius: 9.011px;
  border: 1px solid #f0a500;
  background: rgba(178, 171, 171, 0.1);
  backdrop-filter: blur(16.399999618530273px);
  /* width: 376px; */
  width: 100%;
  min-height: 349px;
  padding: 0rem 1.5rem;
  margin-top: 1rem;
  margin-bottom: 2rem !important;
  position: relative;
  display: inline-block;
  overflow: hidden;
  /* backdrop-filter: blur(2px);
  transition: ease-out;
  transition-duration: 1000ms;
  &::before {
    content: "";
    background: #f0a500;
    width: 30%;
    height: 100%;
    top: 0%;
    left: -125%;

    transform: rotate(30deg);

    position: absolute;

    transition: ease-out;
    transition-duration: 1000ms;
  }

  &:hover::before {
    left: 150%;
  } */

  &::after {
    content: "";
    position: absolute;
    top: -110%;
    left: -210%;
    width: 200%;
    height: 200%;
    opacity: 0;
    transform: rotate(30deg);
    background: rgba(255, 255, 255, 0.13);
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 77%,
      rgba(239, 165, 28, 0.8) 92%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  &:hover::after {
    opacity: 1;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
    transition-duration: 1.5s, 1.5s, 1s;
    transition-timing-function: ease;
  }

  /* -webkit-mask: linear-gradient(135deg, #000c 40%, #000, #000c 60%) 100% 100%/250%
    250%;
  transition: 1s;
  &:hover {
    -webkit-mask-position: 0 0;
  } */

  .info-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.3rem;
    h5 {
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 21.627px; /* 135.169% */
      margin-bottom: 0rem;

      @media (max-width: 767px) {
        font-size: 14px;
      }
    }

    p {
      color: #f3f3f3;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 21.627px; /* 135.169% */
      margin-bottom: 0rem;

      @media (max-width: 767px) {
        font-size: 14px;
      }
    }
  }
`;

export const Name = styled.h5`
  color: #ffa701;
  font-size: 25.646px;
  font-style: normal;
  font-weight: 600;
  line-height: 38.424px; /* 149.823% */
  text-transform: capitalize;
  text-align: center;
  margin-top: 2rem;
`;

export const MainWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 2rem;
  padding-bottom: 2rem;
`;
