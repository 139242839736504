import { MainCol, MainContainer, MainRow } from "../common";
import NavbarComp from "components/common/navbar";
import { CardDiv, MainSection, Name, Title } from "./elements";
const HomeCom = () => {
  const data = [
    {
      title: "Lite",
      duration: "7 days",
      // description: "Entry-level staking",
      rewards: "16% APY",
      minstake: "15",
      penality: "10%",
    },
    {
      title: "Standard",
      duration: "30 days",
      // description: "Entry-level staking",
      rewards: "27% APY",
      minstake: "25",
      penality: "10%",
    },
    {
      title: "Pro",
      duration: "90 days",
      // description: "Entry-level staking",
      rewards: "39% APY",
      minstake: "40",
      penality: "10%",
    },
    {
      title: "Moon",
      duration: "180 days",
      // description: "Entry-level staking",
      rewards: "57% APY",
      minstake: "35",
      penality: "10%",
    },
    {
      title: "Mars",
      duration: "365 days",
      // description: "Entry-level staking",
      rewards: "81% APY",
      minstake: "43",
      penality: "10%",
    },
  ];

  return (
    <>
      <NavbarComp />
      <MainSection>
        <MainContainer>
          <Title>
            Staking Tiers <span>(onschain)</span>
          </Title>
          <div className="main-div">
            <MainRow className="main-row">
              {data.map((item) => (
                <MainCol lg={4}>
                  <CardDiv>
                    <Name>{item.title}</Name>
                    <div className="info-div" style={{ marginTop: "2.5rem" }}>
                      <h5>Staking Duration:</h5>
                      <p>{item.duration}</p>
                    </div>
                    {/* <div className="info-div">
                      <h5>Description:</h5>
                      <p>{item.description}</p>
                    </div> */}
                    <div className="info-div">
                      <h5>Rewards:</h5>
                      <p>{item.rewards}</p>
                    </div>
                    <div className="info-div">
                      <h5>Min Amount Stake:</h5>
                      <p>{item.minstake}</p>
                    </div>
                    <div className="info-div">
                      <h5>Penalty:</h5>
                      <p>{item.penality}</p>
                    </div>
                  </CardDiv>
                </MainCol>
              ))}
            </MainRow>
          </div>
        </MainContainer>
      </MainSection>
    </>
  );
};

export default HomeCom;
