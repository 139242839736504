import styled from "styled-components";

export const MainSection = styled.section`
  margin-top: 6rem;

  .main-tab-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const TabsWrapper = styled.div`
  width: 464px;
  height: 52px;
  display: flex;
  gap: 10px;
  background-color: rgba(178, 171, 171, 0.1);
  backdrop-filter: blur(16.399999618530273px);
  border: 1px solid #525253;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  padding: 0rem 0.5rem;

  @media (max-width: 767px) {
    width: 100%;
  }

  div {
    flex: 1;
    display: flex;
    height: 37px;
    justify-content: center;
    align-items: center;
  }
  h5 {
    color: #525253;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 0rem;
    cursor: pointer;

    @media (max-width: 767px) {
      font-size: 10px;
    }
  }

  .tab-active {
    border-radius: 11px;
    background: #f0a500;

    cursor: pointer;

    h5 {
      color: #fff !important;
    }
  }

  .non-active {
    &:hover {
      border-radius: 11px;
      background: #323232;
      cursor: pointer;
    }
  }
`;

export const ContentDiv = styled.div`
  margin-top: 2.5rem;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
`;

export const Tablediv = styled.div`
  width: 100%;

  @media (max-width: 1200px) {
    height: auto;
  }

  @media (max-width: 992px) {
    min-width: 900px;
  }

  &.other-tab-wrapper {
    @media (max-width: 992px) {
      min-width: 100%;
    }
    @media (max-width: 768px) {
      min-width: 700px;
    }
  }
`;

export const MobileTablediv = styled.div`
  border-radius: 9.011px;
  border: 1px solid #f0a500;
  background: rgba(178, 171, 171, 0.1);
  backdrop-filter: blur(16.399999618530273px);
  width: 100%;
`;

export const ClaimRewardDiv = styled.div`
  border-radius: 9.011px;
  border: 1px solid #f0a500;
  background: rgba(178, 171, 171, 0.1);
  backdrop-filter: blur(16.399999618530273px);
  width: 451px;
  height: 314px;

  @media (max-width: 767px) {
    width: 100%;
    margin-bottom: 2rem;
  }

  .data-div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.5rem;
    padding: 0rem 1.5rem;
    h5 {
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: 21.627px; /* 135.169% */
      margin-bottom: 0rem;
    }

    p {
      color: #f3f3f3;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 21.627px; /* 135.169% */
      margin-bottom: 0rem;
    }
  }
`;
export const MainWrapper = styled.div`
  .whole-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3rem;

    @media (max-width: 1200px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .table-wrapper {
    width: 100%;
    border-radius: 9.011px;
    border: 1px solid #f0a500;
    background: rgba(178, 171, 171, 0.1);
    backdrop-filter: blur(16.399999618530273px);
    @media (max-width: 992px) {
      display: inline-flex;
      overflow-x: auto;
      /* display: none; */
    }
  }

  .mobile-table-wrapper {
    width: 100%;
    @media (min-width: 992px) {
      display: none;
    }
  }
`;

export const HeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #4c4b4c;
  padding: 1.5rem 2rem;

  div {
    &.other-tab-first-div {
      flex: 1;
    }
    &:nth-child(1) {
      width: 100px;
    }
    &:nth-child(2) {
      flex: 1;
    }
    &:nth-child(3) {
      flex: 1;
    }
    &:nth-child(4) {
      flex: 1;
    }
    &:nth-child(5) {
      flex: 1;
    }
    &:nth-child(6) {
      width: 100px;
    }
  }

  h5 {
    color: #ffa701;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 21.627px; /* 135.169% */
    text-transform: capitalize;
    margin: 0rem;
    text-align: center;

    &.other-tab-first-heading {
      text-align: start;
    }
  }
`;

export const MobileHeaderDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #4c4b4c;
  padding: 1rem 0.5rem;

  div {
    &.other-tab-first-div {
      flex: 1;
    }
    &:nth-child(1) {
      width: 50px;
    }
    &:nth-child(2) {
      flex: 1;
    }
    &:nth-child(3) {
      flex: 1;
    }
    &:nth-child(4) {
      flex: 1;
    }
    &:nth-child(5) {
      flex: 1;
    }
    &:nth-child(6) {
      width: 50px;
    }
  }

  h5 {
    color: #ffa701;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 21.627px; /* 135.169% */
    text-transform: capitalize;
    margin: 0rem;
    text-align: center;

    &.other-tab-first-heading {
      text-align: start;
    }
  }
`;

export const BodyDiv = styled.div`
  padding: 1rem 2rem 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #4c4b4c;

  div {
    &.other-tab-first-div {
      flex: 1;
    }
    &:nth-child(1) {
      /* width: 100px; */
    }
    &:nth-child(2) {
      flex: 1;
    }
    &:nth-child(3) {
      flex: 1;
    }
    &:nth-child(4) {
      flex: 1;
    }
    &:nth-child(5) {
      flex: 1;
    }
    &:nth-child(6) {
      width: 100px;
    }
  }

  h5 {
    color: #fff;
    margin: 0rem;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 21.627px; /* 135.169% */
    text-align: center;
  }

  .unstake-btn {
    color: #fff;
    font-size: 13.562px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    border-radius: 5px;
    border: 0.589px solid #f0a500;
    width: 104.197px;
    height: 32.375px;
    background-color: transparent;

    &:hover {
      color: #fff;
      border-radius: 5px;
      border: 0.753px solid #f0a500;
      background: #f0a500;
    }

    &:disabled {
      border-radius: 0.3125rem;
      border: 1px solid #525253;
      color: #fff;
      cursor: not-allowed;
      opacity: 0.4;
    }

    &:disabled:hover {
      background-color: transparent;
    }
  }
`;

export const MainBodyDiv = styled.div`
  height: 244px;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 100px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const MainTitle = styled.h5`
  color: #ffa701;

  font-size: 25.646px;
  font-style: normal;
  font-weight: 600;
  line-height: 38.424px; /* 149.823% */
  text-transform: capitalize;
  text-align: center;
  margin-top: 1.5rem;
`;

export const ClaimBtn = styled.button`
  color: #fff;
  font-size: 13.562px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 5px;
  border: 0.589px solid #f0a500;
  width: 114.197px;
  height: 32.375px;
  background-color: transparent;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2.5rem;

  &:hover {
    border-radius: 5px;
    border: 0.753px solid #f0a500;
    background: #f0a500;
    color: #fff;
  }

  &:disabled {
    border-radius: 0.3125rem;
    border: 1px solid #525253;
    color: #fff;
    cursor: not-allowed;
    opacity: 0.4;
  }

  &:disabled:hover {
    background-color: transparent;
  }
`;

export const NoDataDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  h5 {
    color: #525253;
    font-size: 0.8575rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-top: 1rem;
  }
`;
