import React from "react";
import {
  BtnWrapper,
  CancelBtn,
  ConformBtn,
  MainSection,
  Text,
  Title,
} from "./elements";
import { IoMdClose } from "react-icons/io";
import { Link } from "react-router-dom";
import { IoWarningOutline } from "react-icons/io5";
const UnstakingModal = ({ handleCancel, handleUnstake }) => {
  const web3Call = async () => {
    try {
      await handleUnstake();
      handleCancel();
    } catch (error) {
      console.log("web3 call error", error);
    }
  };
  return (
    <MainSection>
      <div className="close-icon">
        <IoMdClose
          color="rgba(255, 167, 1, 0.40)"
          fontSize={20}
          cursor="pointer"
          onClick={handleCancel}
        />
      </div>
      <Title>
        <IoWarningOutline color="#C20000" /> Early Unstaking!
      </Title>
      <Text>
        Your lock period is not completed now if you want to unstake you will
        pay 10% penality fee.
      </Text>
      <BtnWrapper>
        <CancelBtn onClick={handleCancel}>Cancel</CancelBtn>
        <Link to="">
          <ConformBtn onClick={() => web3Call()}>Confirm</ConformBtn>
        </Link>
      </BtnWrapper>
    </MainSection>
  );
};

export default UnstakingModal;
