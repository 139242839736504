import React, { useState } from "react";
import {
  FirstDiv,
  FormDiv,
  MainDiv,
  MaxBtn,
  StakeBtn,
  ValueDiv,
} from "./elements";
import { Form } from "react-bootstrap";
import { CommonUtility } from "utility/common";
import { useWalletConnectClient } from "services/walletServices";
import { erc20Abi } from "utility/abis/erc20";
import environment from "environment";
import { stakingAbi } from "utility/abis/stakingAbi";
import { ToastMessage } from "components/common";
import Loading from "components/common/loaders/loading";

const TabsContent = ({ balance, tier, getUserBalance }) => {
  const { web3Provider, chain, account } = useWalletConnectClient();
  const [amount, setAmount] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const handleAmountChange = (event) => {
    const value = event.target.value;
    setAmount(value);
  };
  const handleMax = () => {
    setAmount(balance);
  };
  const handlePercentage = (percent) => {
    const amountToSet = (+balance * +percent) / 100;
    setAmount(amountToSet);
  };
  const handleStake = async () => {
    try {
      setIsLoading(true);
      const stakingContract = CommonUtility.contract(
        web3Provider,
        stakingAbi,
        environment.STAKING_CONTRACT[environment.DEFAULT_CHAIN]
      );
      const amountInWei = CommonUtility.convertToWei(
        amount,
        environment.DEPOSIT_TOKEN_DECIMALS
      );
      const tierDetail = await stakingContract.methods
        .getStakingDetails(tier)
        .call();
      console.log("tierDetail", +amountInWei, +tierDetail?.minStakeAmount);
      if (+amountInWei < +tierDetail?.minStakeAmount)
        throw "Minimum stake amount required.";
      const isAlreadyStaked = await stakingContract.methods
        .getUserStakingStatus(tier, account)
        .call();
      if (isAlreadyStaked) throw "One stake per tier allowed.";
      const token = CommonUtility.contract(
        web3Provider,
        erc20Abi,
        environment.DEPOSIT_TOKEN[environment.DEFAULT_CHAIN]
      );
      const allowanceInWei = await token.methods
        .allowance(
          account,
          environment.STAKING_CONTRACT[environment.DEFAULT_CHAIN]
        )
        .call();
      const allowance = CommonUtility.convertFromWei(
        allowanceInWei,
        environment.DEPOSIT_TOKEN_DECIMALS
      );
      if (+allowance < +amount) {
        const approveEstimatedGasLimit = await token.methods
          .approve(
            environment.STAKING_CONTRACT[environment.DEFAULT_CHAIN],
            amountInWei
          )
          .estimateGas({ from: account });
        const approveGasPrice = await web3Provider.eth.getGasPrice();
        const approval = await token.methods
          .approve(
            environment.STAKING_CONTRACT[environment.DEFAULT_CHAIN],
            amountInWei
          )
          .send({
            from: account,
            gasLimit: web3Provider.utils.toHex(
              +approveEstimatedGasLimit + 100000
            ),
            gasPrice: web3Provider.utils.toHex(+approveGasPrice + 1000),
          });
        if (approval && approval.code == 4001)
          throw "User denied the transaction";
        if (!approval.status) throw "Transaction Failed";
      }
      const estimatedGasLimit = await stakingContract.methods
        .stake(tier, amountInWei)
        .estimateGas({ from: account });
      console.log("estimatedGasLimit", estimatedGasLimit);
      const currentGasPrice = await web3Provider.eth.getGasPrice();
      console.log("currentGasPrice", currentGasPrice);
      const txn = await stakingContract.methods.stake(tier, amountInWei).send({
        from: account,
        gasLimit: web3Provider.utils.toHex(+estimatedGasLimit + 100000),
        gasPrice: web3Provider.utils.toHex(+currentGasPrice + 1000),
      });
      if (txn && txn.code == 4001) throw "User denied the transaction";
      if (!txn.status) throw "Transaction Failed";
      if (txn.status) {
        setAmount(0);
        setIsLoading(false);
        ToastMessage("Success!", "Transaction successful", "success");
      }
      setIsLoading(false);
      await getUserBalance();
    } catch (error) {
      console.log("handleStake error", error);
      setIsLoading(false);
      if (error?.message) {
        ToastMessage("Error", error?.message, "error");
      } else {
        ToastMessage("Error", error, "error");
      }
    }
  };
  return (
    <div>
      <MainDiv>
        {isLoading && <Loading content={"Transaction in progress"} />}

        <FirstDiv>
          <h5>Amount:</h5>
          <p>
            Balance: <span>{balance ? balance : "--"}</span>
          </p>
        </FirstDiv>
        <FormDiv>
          <Form.Control
            type="number"
            min={0}
            placeholder="Enter amount"
            value={amount}
            onChange={(event) => handleAmountChange(event)}
          />
          <MaxBtn
            onClick={() => handleMax()}
            disabled={!web3Provider || !balance || +balance == 0}
          >
            MAX
          </MaxBtn>
        </FormDiv>

        <div className="value-main-div">
          <ValueDiv
            onClick={() => handlePercentage(25)}
            className={
              !web3Provider || !balance || +balance == 0 ? "disable" : ""
            }
          >
            {" "}
            25%
          </ValueDiv>
          <ValueDiv
            onClick={() => {
              if (web3Provider && balance && +balance != 0) {
                handlePercentage(50);
              }
            }}
            className={
              !web3Provider || !balance || +balance == 0 ? "disable" : ""
            }
          >
            50%
          </ValueDiv>
          <ValueDiv
            onClick={() => handlePercentage(75)}
            className={
              !web3Provider || !balance || +balance == 0 ? "disable" : ""
            }
          >
            75%
          </ValueDiv>
          <ValueDiv
            onClick={() => handlePercentage(100)}
            className={
              !web3Provider || !balance || +balance == 0 ? "disable" : ""
            }
          >
            100%
          </ValueDiv>
        </div>

        <StakeBtn
          onClick={handleStake}
          disabled={!web3Provider || +amount <= 0 || !balance || +balance == 0}
        >
          Stake
        </StakeBtn>
      </MainDiv>
    </div>
  );
};

export default TabsContent;
