import React, { useEffect, useState } from "react";
import { ContentDiv, MainSection, TabsWrapper } from "./elements";
import NavbarComp from "components/common/navbar";
import { MainContainer } from "components/common";
import TabsContent from "./tabContent";
import environment from "environment";
import { useWalletConnectClient } from "services/walletServices";
import { CommonUtility } from "utility/common";
import { stakingAbi } from "utility/abis/stakingAbi";

const UnStakeComp = () => {
  const { web3Provider, chain, account } = useWalletConnectClient();
  const [activeDiv, setActiveDiv] = useState(environment.TIER_1);
  const [userStakes, setUserStakes] = useState([]);
  const handleDivClick = (divNumber) => {
    setActiveDiv(divNumber);
  };
  const getStakeInfo = async () => {
    try {
      const stakingContract = CommonUtility.contract(
        web3Provider,
        stakingAbi,
        environment.STAKING_CONTRACT[environment.DEFAULT_CHAIN]
      );
      let stakesArray = [];
      for (let i = 0; i < environment.TOTAL_TIERS; i++) {
        console.log("account", account);
        const stakeAmountAndTime = await stakingContract.methods
          .getStakeAmountAndTime(i, account)
          .call();
        console.log("stakeAmountAndTime", stakeAmountAndTime);
        const stakeEndTime = await stakingContract.methods
          .getStakeEndTime(i, account)
          .call();
        console.log("stakeEndTime", stakeEndTime);
        const claimedRewards = await stakingContract.methods
          .getUserClaimedRewards(i, account)
          .call();
        console.log("claimedRewards", claimedRewards);
        const claimableRewards = await stakingContract.methods
          .calculateRewards(account, i)
          .call();
        console.log("claimableRewards", claimableRewards);
        const obj = {
          timestamp: stakeAmountAndTime?.stakingTime,
          amount: stakeAmountAndTime?.stakingAmount,
          apy: environment.TIER_APY[i],
          endTime: stakeEndTime,
          claimed: claimedRewards,
          rewards: claimableRewards,
          tier: i,
        };
        stakesArray.push(obj);
      }
      setUserStakes(stakesArray);
    } catch (error) {
      console.log("getstake info error", error);
    }
  };
  useEffect(() => {
    if (web3Provider && chain && account) {
      getStakeInfo();
    }
  }, [web3Provider, chain, account]);
  return (
    <>
      <NavbarComp />
      <MainSection>
        <MainContainer>
          <div className="main-tab-div">
            <TabsWrapper>
              <div
                onClick={() => handleDivClick(environment.TIER_1)}
                className={`${
                  activeDiv === environment.TIER_1 ? "tab-active" : "non-active"
                }`}
              >
                <h5>Lite</h5>
              </div>
              <div
                onClick={() => handleDivClick(environment.TIER_2)}
                className={`${
                  activeDiv === environment.TIER_2 ? "tab-active" : "non-active"
                }`}
              >
                <h5>Standard</h5>
              </div>
              <div
                onClick={() => handleDivClick(environment.TIER_3)}
                className={`${
                  activeDiv === environment.TIER_3 ? "tab-active" : "non-active"
                }`}
              >
                <h5>Pro</h5>
              </div>
              <div
                onClick={() => handleDivClick(environment.TIER_4)}
                className={`${
                  activeDiv === environment.TIER_4 ? "tab-active" : "non-active"
                }`}
              >
                <h5>Moon</h5>
              </div>
              <div
                onClick={() => handleDivClick(environment.TIER_5)}
                className={`${
                  activeDiv === environment.TIER_5 ? "tab-active" : "non-active"
                }`}
              >
                <h5>Mars</h5>
              </div>
            </TabsWrapper>
          </div>

          <ContentDiv>
            {activeDiv === environment.TIER_1 ? (
              <>
                <TabsContent
                  userStakes={userStakes[environment.TIER_1]}
                  getStakeInfo={getStakeInfo}
                />
              </>
            ) : activeDiv === environment.TIER_2 ? (
              <TabsContent
                userStakes={userStakes[environment.TIER_2]}
                getStakeInfo={getStakeInfo}
              />
            ) : activeDiv === environment.TIER_3 ? (
              <TabsContent
                userStakes={userStakes[environment.TIER_3]}
                getStakeInfo={getStakeInfo}
              />
            ) : activeDiv === environment.TIER_4 ? (
              <TabsContent
                userStakes={userStakes[environment.TIER_4]}
                getStakeInfo={getStakeInfo}
              />
            ) : activeDiv === environment.TIER_5 ? (
              <TabsContent
                userStakes={userStakes[environment.TIER_5]}
                getStakeInfo={getStakeInfo}
              />
            ) : (
              ""
            )}
          </ContentDiv>
        </MainContainer>
      </MainSection>
    </>
  );
};

export default UnStakeComp;
