import React, { useState } from "react";
import { ConnectBtn, MainSection } from "./elements";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { MainContainer } from "../mainStyle/mainStyle";
import { Image } from "react-bootstrap";
import { logo } from "assets";
import { Link, useLocation } from "react-router-dom";
import { useAppDispatch } from "store/store";
import { mainModel } from "store/redux/slices/helperSlices/modelSlice";
import { MainModel } from "../../common";
import { CommonUtility } from "utility/common";
import { useWalletConnectClient } from "services/walletServices";

const NavbarComp = () => {
  const [connectModel, setConnectModel] = useState(false);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { account, web3Provider } = useWalletConnectClient();

  const handleModelOpen = () => {
    setConnectModel(true);
    dispatch(mainModel(true));
  };

  return (
    <MainSection>
      <MainModel connectModel={connectModel} />

      <Navbar expand="lg">
        <MainContainer>
          <Navbar.Brand
            onClick={() => window.open("https://www.onschain.com/", "_blank")}
          >
            <Image src={logo} style={{ cursor: "pointer" }} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="m-auto">
              <div className="menu-link">
                <Link
                  to="/"
                  className={`${location.pathname === "/" ? "active-nav" : ""}`}
                >
                  Home
                </Link>
                <Link
                  to="/stake"
                  className={`${
                    location.pathname === "/stake" ? "active-nav" : ""
                  }`}
                >
                  Stake
                </Link>
                <Link
                  to="/un-stake"
                  className={`${
                    location.pathname === "/un-stake" ? "active-nav" : ""
                  }`}
                >
                  Unstake
                </Link>
              </div>
            </Nav>
            {web3Provider ? (
              <ConnectBtn>{CommonUtility.addressConvertor(account)}</ConnectBtn>
            ) : (
              <ConnectBtn onClick={handleModelOpen}>Connect Wallet</ConnectBtn>
            )}
          </Navbar.Collapse>
        </MainContainer>
      </Navbar>
    </MainSection>
  );
};

export default NavbarComp;
