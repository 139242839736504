import styled from "styled-components";

export const MainSection = styled.section`
  a {
    color: #fff;
    font-size: 15px;
    font-style: normal;
    font-weight: 200;
    line-height: 60px; /* 400% */
    text-decoration: none;
    padding: 0rem 0.6rem;
    @media (max-width: 990px) {
      text-align: center;
      line-height: normal;
    }

    &:not(:first-child) {
      margin-left: 1rem;

      @media (max-width: 990px) {
        margin-left: 0rem;
      }
    }
  }

  .active-nav {
    color: #f0a500;

    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 60px; /* 400% */
  }

  .nav-link {
    color: #fff;
    font-size: 15px;
    font-style: normal;
    font-weight: 200;
    line-height: 60px; /* 400% */

    @media (max-width: 990px) {
      text-align: center;
      line-height: normal;
    }

    &:not(:first-child) {
      margin-left: 1rem;

      @media (max-width: 990px) {
        margin-left: 0rem;
      }
    }
  }

  .navbar-toggler {
    background-color: #fff;
  }

  .menu-link {
    margin-right: 5rem;

    @media (max-width: 990px) {
      margin-right: 0rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const ConnectBtn = styled.button`
  color: #f0a500;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  border-radius: 5px;
  border: 1px solid #f0a500;
  width: 164px;
  height: 43px;
  background-color: transparent;

  @media (max-width: 990px) {
    margin-top: 1rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  &:hover {
    border-radius: 5px;
    border: 0.989px solid #f0a500;
    background: #f0a500;
    color: #fff;
  }
`;
