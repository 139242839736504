import React, { useState } from "react";
import {
  ContentDiv,
  FirstDiv,
  FormDiv,
  MainDiv,
  MainSection,
  MaxBtn,
  StakeBtn,
  TabsWrapper,
  ValueDiv,
} from "./elements";
import NavbarComp from "components/common/navbar";
import { MainContainer } from "components/common";
import { Form } from "react-bootstrap";
import TabsContent from "./tabsContent";
import environment from "environment";

const StakeComp = ({ balance, getUserBalance }) => {
  const [activeDiv, setActiveDiv] = useState(environment.TIER_1);
  const handleDivClick = (divNumber) => {
    setActiveDiv(divNumber);
  };

  return (
    <>
      <NavbarComp />
      <MainSection>
        <MainContainer>
          <div className="main-tab-div">
            <TabsWrapper>
              <div
                onClick={() => handleDivClick(environment.TIER_1)}
                className={`${
                  activeDiv === environment.TIER_1 ? "tab-active" : "non-active"
                }`}
              >
                <h5>Lite</h5>
              </div>
              <div
                onClick={() => handleDivClick(environment.TIER_2)}
                className={`${
                  activeDiv === environment.TIER_2 ? "tab-active" : "non-active"
                }`}
              >
                <h5>Standard</h5>
              </div>
              <div
                onClick={() => handleDivClick(environment.TIER_3)}
                className={`${
                  activeDiv === environment.TIER_3 ? "tab-active" : "non-active"
                }`}
              >
                <h5>Pro</h5>
              </div>
              <div
                onClick={() => handleDivClick(environment.TIER_4)}
                className={`${
                  activeDiv === environment.TIER_4 ? "tab-active" : "non-active"
                }`}
              >
                <h5>Moon</h5>
              </div>
              <div
                onClick={() => handleDivClick(environment.TIER_5)}
                className={`${
                  activeDiv === environment.TIER_5 ? "tab-active" : "non-active"
                }`}
              >
                <h5>Mars</h5>
              </div>
            </TabsWrapper>
          </div>

          <ContentDiv>
            {activeDiv === environment.TIER_1 ? (
              <>
                <TabsContent
                  balance={balance}
                  tier={environment.TIER_1}
                  getUserBalance={getUserBalance}
                />
              </>
            ) : activeDiv === environment.TIER_2 ? (
              <TabsContent
                balance={balance}
                tier={environment.TIER_2}
                getUserBalance={getUserBalance}
              />
            ) : activeDiv === environment.TIER_3 ? (
              <TabsContent
                balance={balance}
                tier={environment.TIER_3}
                getUserBalance={getUserBalance}
              />
            ) : activeDiv === environment.TIER_4 ? (
              <TabsContent
                balance={balance}
                tier={environment.TIER_4}
                getUserBalance={getUserBalance}
              />
            ) : activeDiv === environment.TIER_5 ? (
              <TabsContent
                balance={balance}
                tier={environment.TIER_5}
                getUserBalance={getUserBalance}
              />
            ) : (
              ""
            )}
          </ContentDiv>
        </MainContainer>
      </MainSection>
    </>
  );
};

export default StakeComp;
