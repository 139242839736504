import styled from "styled-components";

export const Text = styled.h5`
  color: #fff;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem; /* 200% */
  text-transform: capitalize;
  text-align: center;
`;
