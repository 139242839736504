import StakeComp from "components/stakeComp";
import environment from "environment";
import React, { useEffect, useState } from "react";
import { useWalletConnectClient } from "services/walletServices";
import { erc20Abi } from "utility/abis/erc20";
import { CommonUtility } from "utility/common";

const Stake = () => {
  const { web3Provider, chain, account } = useWalletConnectClient();
  const [balance, setBalance] = useState(null);
  const getUserBalance = async () => {
    try {
      const contract = CommonUtility.contract(
        web3Provider,
        erc20Abi,
        environment.DEPOSIT_TOKEN[environment.DEFAULT_CHAIN]
      );
      const userBalanceInWei = await contract.methods.balanceOf(account).call();
      const userBalance = CommonUtility.convertFromWei(
        userBalanceInWei,
        environment.DEPOSIT_TOKEN_DECIMALS
      );
      setBalance(userBalance);
    } catch (error) {
      console.log("get User balance function error");
    }
  };
  useEffect(() => {
    if (web3Provider && chain && account) {
      getUserBalance();
    }
  }, [web3Provider, chain, account]);
  return (
    <div>
      <StakeComp balance={balance} getUserBalance={getUserBalance}/>
    </div>
  );
};

export default Stake;
